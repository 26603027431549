<template>
  <div class="project">
    <Progress
      size="mini"
      :active-index="1"
      :step-list="stepList"
    />
    <!-- <div class="titleBar">
      <div class="title">
        <span>{{ insureProject.name }}</span>
        <small>({{ dateFormat(insureProject.showTime) }})</small>
      </div>
      <div>
        <a-button @click="goBack">
          {{ back }} 返回
        </a-button>
      </div>
    </div> -->
    <div class="search">
      <img
        src="@/assets/image/icon-ss.png"
        alt=""
      >
      <a-input
        v-model="query.projectName"
        placeholder="输入项目或标段名称搜索"
      />
      <a-input
        v-model="query.sectionCode"
        placeholder="输入标段编号搜索"
      />
      <!-- <div class="phase">
        <span>标段阶段</span>
        <a-select
          v-model="query.projectStatus"
        >
          <a-select-option value="4">
            全部
          </a-select-option>
          <a-select-option value="0">
            正常
          </a-select-option>
          <a-select-option value="1">
            暂停
          </a-select-option>
          <a-select-option value="2">
            终止
          </a-select-option>
          <a-select-option value="3">
            流标
          </a-select-option>
        </a-select>
      </div> -->
      <a-button
        type="primary"
        @click="fetch(1)"
      >
        查询
      </a-button>
      <a-button
        type="primary"
        @click="reset"
      >
        重置
      </a-button>
      <!-- <a-button
        class="back"
        @click="goBack"
      >
        {{ back }} 返回
      </a-button> -->
    </div>
    <!-- 表格 -->
    <a-spin :spinning="loading">
      <div class="table">
        <!-- v-for -->
        <a-table
          :columns="columns"
          :pagination="pagination"
          :loading="loading"
          :data-source="data"
          :scroll="{ y: 500 }"
          @change="pageChange"
        >
          <span
            slot="marginAmount"
            slot-scope="space"
            class="red"
          >
            {{ space }}
          </span>
          <span
            slot="action"
            slot-scope="space, text"
          >
            <a-button
              @click="apply(text)"
              v-if="text.applyState === '1'"
              type="primary"
            >申请</a-button>
            <a-button
              v-else
              type="primary"
              disabled
            >已申请</a-button>
          </span>
        </a-table>
        <!-- <div
          class="forTable"
          v-for="(item,index) in data"
          :key="index"
        >
          <div class="bottom">
            <div class="div1">
              <p>项目名称</p>
              <p>{{ item.projectName }}</p>
            </div>
            <div class="div2">
              <p>标段名称</p>
              <p>{{ item.name }}</p>
            </div>
            <div class="div3">
              <p>标段编号</p>
              <p>{{ item.code }}</p>
            </div>
            <div class="div4">
              <p>开标时间</p>
              <p>{{ item.bidOpenTime }}</p>
            </div>
            <div class="div5">
              <p>投标保证金金额</p>
              <p>￥{{ item.marginAmount }}</p>
            </div>
            <div>
        <a-button
          type="primary"
          v-if="item.applyState === '1'"
          @click="apply(item)"
        >
          申请
        </a-button>
        <a-button
          type="primary"
          v-else
          class="applyed"
        >
          已申请
        </a-button>
      </div>
    </a-spin>
  </div>
  </div> -->
      </div>
    </a-spin>
  </div>
</template>

<script>
import Progress from '@/components/Progress';
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import { insureApply, sectionList } from '@/api/insure/project.js';
import { dateFormat } from '@/utils/format.js';
import { info } from '@/api/user/user.js';
export default {
  name: 'ProView',
  components: {
    Progress,
  },
  data() {
    return {
      activeIndex: 0,
      dateFormat,
      back: '<',
      locale,
      loading: false,
      abnormal: '', // 标段异常
      headers: {
        authorization: 'authorization-text',
      }, // 请求头
      visible: false, // 弹窗显示
      confirmLoading: false, // 确定加载
      insureProject: {},
      query: { projectStatus: '4' },
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      data: [],
      columns: [
        {
          title: '项目名称',
          dataIndex: 'projectName',
          key: 'projectName',
          slots: { title: '项目名称' },
          scopedSlots: { customRender: 'projectName' },
        },
        {
          title: '项目编号',
          dataIndex: 'projectNum',
          key: 'projectNum',
        },
        {
          title: '标段名称',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: '标段编号',
          dataIndex: 'sectionCode',
          key: 'sectionCode',
        },
        {
          title: '开标时间',
          dataIndex: 'marginDeadLine',
          key: 'marginDeadLine',
        },
        {
          title: '公告时间',
          dataIndex: 'bulletinReleaseTime',
          key: 'bulletinReleaseTime',
        },
        {
          title: '保证金金额',
          dataIndex: 'marginAmount',
          key: 'marginAmount',
          slots: { title: '保证金金额' },
          scopedSlots: { customRender: 'marginAmount' },
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      options: [
        { label: '标段延期', value: '0' },
        { label: '标段流标', value: '1' },
        { label: '标段终止', value: '2' },
      ],
      stepList: [{
        name: '选择项目',
        flag: 0,
      }, {
        name: '确认项目',
        flag: 1,
      }, {
        name: '确认投保',
        flag: 2,
      }, {
        name: '投保审核',
        flag: 3,
      }, {
        name: '保费支付',
        flag: 4,
      }, {
        name: '保函发放',
        flag: 5,
      }],
      projectStatus: [{
        label: '正常',
        value: '0',
      }, {
        label: '暂停',
        value: '1',
      }, {
        label: '终止',
        value: '2',
      }, {
        label: '流标',
        value: '3',
      }],
    };
  },
  mounted() {
    this.insureProject = JSON.parse(sessionStorage.getItem('insureProject'));
    this.fetch(1);
    // if (typeof this.insureProject === 'string') {
    //   this.fetch({ projectNum: this.insureProject });
    // } else {
    //   this.fetch({ projectNum: this.insureProject.projectNum });
    // }
    if (this.$route.query.count) {
      this.activeIndex = Number(this.$route.query.count);
    }
  },
  methods: {
    status(data) {
      const statu = this.projectStatus.filter(item => {
        // indexOf获取value在数组中出现的首个位置
        return item.value === data;
      });
      console.log(statu);
      // return statu[0].label;
    },
    apply(item) {
      console.log(item);
      info().then(r => {
        if (r.data.data.accCustomerFlag) {
          insureApply({ projectNum: item.projectNum, sectionId: item.id }).then(res => {
            if (res.data.status === 200) {
              this.$router.push({ path: '/insure', query: { orderNo: res.data.data.orderNo } });
            }
          });
        } else {
          this.$router.push({ path: '/user' });
        }
      });
      // this.$router.push({ name: 'Project', query: { count: 2 } });
      // sessionStorage.setItem('projectSectionId', JSON.stringify(item.id));
    },
    reset() {
      this.query.projectName = null;
      this.query.sectionCode = null;
      this.query.projectStatus = '4';
      this.fetch(1);
    },
    fetch(page) {
      this.loading = true;
      sectionList({ projectName: this.query.projectName, sectionCode: this.query.sectionCode, size: 10, page }).then(res => {
        this.loading = false;
        this.data = res.data.data.content;
        this.pagination.total = res.data.data.totalElements;
        this.pagination.current = page;
      });
    },
    pageChange(e) {
      this.fetch(e.current);
    },
    // // 弹窗文件上传
    // handleChange(info) {
    //   if (info.file.status !== 'uploading') {
    //   }
    //   if (info.file.status === 'done') {
    //     this.$message.success(`${info.file.name} file uploaded successfully`);
    //   } else if (info.file.status === 'error') {
    //     this.$message.error(`${info.file.name} file upload failed.`);
    //   }
    // },
    // // 弹窗显示
    // showModal() {
    //   this.visible = true;
    // },
    // // 弹窗确定
    // handleOk(e) {
    //   this.ModalText = 'The modal will be closed after two seconds';
    //   this.confirmLoading = true;
    //   setTimeout(() => {
    //     this.visible = false;
    //     this.confirmLoading = false;
    //   }, 2000);
    // },
    // // 弹窗取消
    // handleCancel(e) {
    //   this.visible = false;
    // },
    // 返回
    goBack() {
      this.$router.push({ name: 'Project' });
    },
  },
};
</script>
<style lang="less" scoped>
.red {
  color: crimson;
  font-size: 18px;
  font-weight: 500;
}
.project {
  min-height: 666px;
  .titleBar {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    .title {
      span {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
      }
      small {
        font-size: 16px;
        font-family: Microsoft YaHei;
      }
    }
    button {
      height: 30px;
      background: #FFFFFF;
      border: 1px solid #E1E8EF;
      border-radius: 3px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #AAAAAA;
    }
  }
  .search {
    display: flex;
    margin: 30px 0 20px 0;
    position: relative;
    img {
      width: 14px;
      height: 14px;
      position: absolute;
      top: 9px;
      left: 10px;
      z-index: 99;
    }
    /deep/ .ant-input-affix-wrapper {
      width: 300px;
    }
    /deep/ .ant-input {
      width: 200px;
      height: 32px;
      background: #FFFFFF;
      border: 1px solid #E1E8EF;
      border-radius: 3px;
      padding-left: 40px;
      margin-right: 10px;
    }
    .phase {
      span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #AAAAAA;
        margin: 0 10px 0 30px;
      }
      /deep/ .ant-select-selection {
        width: 120px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #E1E8EF;
        border-radius: 3px;
      }
    }
    button {
      background-color: #007CE2;
      margin-left: 30px;
    }
    .back {
      position: absolute;
      top: 1px;
      right: 0;
      height: 30px;
      background: #FFFFFF;
      border: 1px solid #E1E8EF;
      border-radius: 3px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 600;
      color: #999;
    }
  }
  .tabProject {
    display: flex;
  }
  .table {
    .forTable {
      margin-bottom: 20px;
      border: 1px solid #EBF2F7;
    }
    .top {
      width: 920px;
      height: 80px;
      background: #F7FAFF;
      padding: 20px 40px 17px 30px;
      position: relative;
      font-family: Microsoft YaHei;
      .top1 {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }
      .top2 {
        font-size: 14px;
        font-weight: 400;
        color: #AAAAAA;
        position: absolute;
        right: 41px;
      }
      .top3 {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        margin-top: 5px;
      }
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      width: 920px;
      background: #FFFFFF;
      padding: 25px 40px 3px 30px;
      p {
        margin: 0;
      }
      .div1 {
        width: 160px;
      }
      .div2 {
        width: 160px;
      }
      .div3 {
        width: 180px;
      }
      .div4 {
        width: 100px;
      }
      .div5 {
        width: 70px;
      }
      .div6 {
        width: 65px;
      }
      div {
        margin: 9px;
        p {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          &:nth-child(1) {
            color: #AAAAAA;
          }
          &:nth-child(2) {
            color: #333333;
          }
        }
        a {
          margin-right: 10px;
        }
        button {
          width: 72px;
        }
        .applyed {
          background: #B2D7F6;
          border: #B2D7F6;
        }
      }
    }
  }
}
.table {
    /deep/ .ant-table-thead > tr > th {
      background-color: #F7FAFF;
      font-size: 15px;
      color: #333;
      font-weight: bold;
    }
    /deep/ .ant-table-tbody > tr > td {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      min-width: 45px;
      word-break: break-word; /* 文本行的任意字内断开 */
      word-wrap: break-word; /* IE */
    }
    /deep/ .ant-table-placeholder {
      min-height: 500px;
      .ant-empty-normal {
        margin-top: 220px;
      }
    }
    /deep/ .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
      background-color: #f7faff;
    }
  }
</style>
